<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg3 md9 sm12 pl-4 pt-6>
                    <v-layout row wrap justify-end>
                        <v-menu v-model="menuDate" :close-on-content-click="false"  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                :disabled="accountant_cumulation.AccountantDate > 0"
                                v-model="computedDateFormatted"
                                label="Ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="accountantDate" no-title @input="menuDate = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg9 md9 sm12>
                    <h-currency-input v-model="accountant_cumulation.IncomeAmount" :decimal="2" label="Khoản thu" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg9 md9 sm12>
                    <h-currency-input v-model="accountant_cumulation.Tuikuan" :decimal="2" label="Tuikuan" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg6 md6 sm12>
                    <h-currency-input v-model="accountant_cumulation.OutcomePurchase" :decimal="2" label="Chi mua hàng" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg6 md6 sm12>
                    <h-currency-input v-model="accountant_cumulation.OutcomeOther" :decimal="2" label="Chi khác" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea v-model="accountant_cumulation.Note" label="Ghi chú" class="input-group--focused"></v-textarea>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import configs from "@/configs";
export default {
    data() {
        return {
            menuDate: false,
            accountantDate: moment.utc(Date.now()).toISOString().substr(0, 10)
        };
    },
    computed: {
        ...mapState({
            loading: state => state.accountant_cumulation.loading,
            accountant_cumulation: state => state.accountant_cumulation.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        },
        computedDateFormatted () {
            return this.accountant_cumulation.AccountantDate > 0 ? moment.unix(this.accountant_cumulation.AccountantDate).format(configs.SHORT_DATE_FORMAT) : moment.utc(this.accountantDate).format(configs.SHORT_DATE_FORMAT);
        },
    },
    watch: {
        modifyId() {
            this.refreshData();
        },
        accountant_cumulation() {
            if(this.accountant_cumulation && this.accountant_cumulation.AccountantDate > 0) {
                this.accountantDate = moment.unix(this.accountant_cumulation.AccountantDate).toISOString().substr(0, 10)
            } 
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("accountant_cumulation/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("accountant_cumulation/setDetail", { data: {
                        AccountantDate: 0,
                        IncomeAmount: 0,
                        Tuikuan: 0,
                        OutcomePurchase: 0,
                        OutcomeOther: 0,
                        Note: ''
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("accountant_cumulation/setDetail", {
                data: {
                    ...this.accountant_cumulation,
                    'ReCalculator': 1,
                    'up_sert': 1
                },
                accountantDate: this.accountant_cumulation.AccountantDate > 0 ? this.accountant_cumulation.AccountantDate : moment.utc(this.accountantDate, 'YYYY-MM-DD').unix()
            });
            window.getApp.$emit("APP_NAVIGATE", "AccountantCumulationList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>